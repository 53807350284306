import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MachineTypeService } from '../../services/machine-type.service';
import { UtilService } from '../../services/util.service';
import { MachineType } from '../../models/machine-type.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-machine-type-modal',
  templateUrl: './machine-type-modal.component.html',
  styleUrl: './machine-type-modal.component.scss'
})
export class MachineTypeModalComponent {

  @Input() machineType?: MachineType;
  @Input() idCompany?: string;

  public dataForm: DataFormModel = {
    image: {
      value: '',
      type: 'image',
      label: '',
      required: false,
      accept: 'image/*',
      validators: []
    },
    machineType: {
      value: '',
      type: 'text',
      label: 'Tipo de maquinaria',
      requiredMessage: 'Por favor, ingrese el tipo de maquinaria',
      required: true,
      validators: [Validators.required]
    },
  };

  public submitted: boolean = false;

  public formMachineType: FormGroup;

  public imageBase64: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    public machineTypeService: MachineTypeService,
    public utilService: UtilService,
  ) {
    this.formMachineType = this.utilService.buildForm(this.dataForm);
  }

  ngOnInit(): void {
    if(this.machineType) {
      this.dataForm['machineType'].value = this.machineType.machineType;
      this.formMachineType = this.utilService.buildForm(this.dataForm);
    }
  }

  get f() {
    return this.formMachineType.controls;
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
 /**
  * FUNCTION create
  */
  async create() {

    this.formMachineType.value.status = false;

    console.log(this.formMachineType.value);
    

    const flag = await this.machineTypeService.create(
      this.idCompany!,
      this.formMachineType.value,
    );

    if(flag) {
      this.activeModal.close();
    }

  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗██╗     ███████╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗
  ██╔═══██╗████╗  ██║██╔════╝██║██║     ██╔════╝██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝
  ██║   ██║██╔██╗ ██║█████╗  ██║██║     █████╗  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗
  ██║   ██║██║╚██╗██║██╔══╝  ██║██║     ██╔══╝  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝
  ╚██████╔╝██║ ╚████║██║     ██║███████╗███████╗╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗
   ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION onFileChange
   * 
   * @param event 
   */
  onFileChange(event: any) {
    console.log(event);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file); // toBase64
    reader.onload = () => {
      this.imageBase64 = reader.result as string; // base64 Image src
      console.log(this.imageBase64);
      
    };
    
  }

  /*
  ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
  ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  /**
  * FUNCTION onSubmit
  * 
  * @returns 
  */
  async onSubmit() {

    this.submitted = true;

    if(this.formMachineType.invalid) {
      return;
    }

    this.formMachineType.value.fileBase64 = this.imageBase64;
    this.formMachineType.value.image = undefined;

    if(this.machineType) {

      this.formMachineType.value.status = this.machineType.status;

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
  ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
  * FUNCTION update
  */
  async update() {
    this.machineType!.machineType = this.formMachineType.value.machineType;
    this.machineType!.fileBase64 = this.imageBase64;

    const flag = await this.machineTypeService.update(
      this.machineType!  
    );

    if(flag) {
      this.activeModal.close();
    }
  }

}
