<div class="modal-header">
  <h4 class="modal-title">
    @if (machineType) {
      Editar tipo de maquinaria {{ machineType.machineType }} 
    }

    @if (!machineType) {
      Nuevo tipo de maquinaria
    }
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form
    id="formMachineType"
    [formGroup]="formMachineType"
    (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">

        @if(imageBase64 || (machineType && machineType.file)) {
          <div class="col-12">
            <img [src]="imageBase64 ? imageBase64 : (machineType && machineType!.file) ? machineType.file.urlFile : ''" width="100%">
          </div>
        }

        @for (key of utilService.objectKeys(dataForm); track key) {
          @if(dataForm[key].type == 'text') {
            <div class="col-12 form-group mb-4">
              <label [htmlFor]="key" class="form-label">
                {{ dataForm[key].label }}
              </label>
              <input
                [type]="dataForm[key].type"
                class="form-control"
                formControlName="{{ key }}"
                [ngClass]="{
                  'is-invalid': submitted && f[key].errors
                }">
                @if (submitted && f[key].errors && dataForm[key].required) {
                  <div class="invalid-feedback">
                    @if (f[key].errors!['required']) {
                      <div>{{ dataForm[key].requiredMessage }}</div>
                    }
                  </div>
                }
            </div>
          }

          @if(dataForm[key].type == 'image') {
            <div class="col-12 form-group mb-4">
              <label [htmlFor]="key" class="form-label">
                {{ dataForm[key].label }}
              </label>
              <input 
                class="form-control" 
                type="file" 
                [id]="key"
                [accept]="dataForm[key].accept ?? null"
                (change)="onFileChange($event)">
            </div>
          }
        }

      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formMachineType"
      class="btn btn-primary w-100">
      {{ machineType ? 'Actualizar' : 'Registrar' }}
    </button>
  </div>
</div>